import AppGridContainer from "@crema/components/AppGridContainer";
import AppIconButton from "@crema/components/AppIconButton";
import SearchWithDebounce from "@crema/components/AppSearchBar/SearchWithDebounce";
import WithGridItem from "@crema/components/with/WithGridItem";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Form } from "formik";
import { FaFilter } from "react-icons/fa";
import { FaFileExport } from "react-icons/fa6";
import { GrPowerReset } from "react-icons/gr";

const FilterForm = ({
  values,
  initialValues,
  setFieldValue,
  AdvanceFilter,
  BasicFilter,
  fetchData,
  resetForm,
  handleExportOpen,
  handleFilterOpen,
  ExtraHeader,
  setPage,
  setSearch,
  responsiveScreen = {},
  name,
  setSearchParams,
  hasExport,
  hasReset,
  onResetClick,
  hasSearch,
}) => {
  return (
    <Form style={{ width: "100%" }} noValidate autoComplete="off">
      <AppGridContainer spacing={1}>
        <WithGridItem
          {...responsiveScreen?.name}
          sx={{
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
          className="flex-start"
        >
          {name}&nbsp;
          <ChevronRightIcon />
        </WithGridItem>

        {hasSearch && (
          <WithGridItem {...responsiveScreen?.search}>
            <SearchWithDebounce
              setPage={setPage}
              setSearch={setSearch}
              width={"100%"}
              fullWidth={true}
              sx={{
                "& input": {
                  width: "100% !important",
                  fontSize: "13px",
                  height: "30px !important",
                },
              }}
            />
          </WithGridItem>
        )}

        {BasicFilter &&
          BasicFilter(initialValues, values, setFieldValue, fetchData)}

        {AdvanceFilter &&
          AdvanceFilter(initialValues, values, setFieldValue, fetchData)}

        <WithGridItem {...responsiveScreen?.buttons}>
          {hasReset && (
            <AppIconButton
              buttonProps={{
                onClick: onResetClick
                  ? onResetClick
                  : () => {
                      setSearchParams({});
                      resetForm();
                    },
              }}
              icon={<GrPowerReset />}
              title="Reset"
              sx={buttonStyles}
            />
          )}

          {handleFilterOpen && (
            <AppIconButton
              buttonProps={{
                onClick: handleFilterOpen,
              }}
              icon={<FaFilter />}
              title="Filter"
              sx={buttonStyles}
            />
          )}

          {hasExport && (
            <AppIconButton
              buttonProps={{
                onClick: handleExportOpen,
              }}
              icon={<FaFileExport />}
              title="Export"
              sx={buttonStyles}
            />
          )}

          {ExtraHeader && ExtraHeader()}
        </WithGridItem>
      </AppGridContainer>
    </Form>
  );
};

export default FilterForm;

export const buttonStyles = {
  width: "30px",
  height: "30px",
  padding: 0,
  "& svg": {
    fontSize: "18px",
  },
};
