// dispatch types is constant object for dispatch types in reducer
import { DISPATCH_TYPES } from "constants/ActionTypes";

const reducer = (state, action) => {
  switch (action.type) {
    // It will set all fetched records in state
    case DISPATCH_TYPES.GET_ALL:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.count,
      };

    // It will set all fetched records in state
    case DISPATCH_TYPES.GET_ALL:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.count,
      };

    // It will set state for created record
    case DISPATCH_TYPES.CREATE:
      return {
        ...state,
        data: [...state.data, action.payload],
        count: state.count + 1,
      };

    // It will set state for deleted records
    case DISPATCH_TYPES.DELETE:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.count,
      };

    // It will set state for updated record
    case DISPATCH_TYPES.UPDATE:
      for (let i = 0; i < state.data.length; i++) {
        if (state.data[i]._id === action.payload._id) {
          state.data[i] = action.payload;
          break;
        }
      }
      return state;

    // It will set all fetched records in state
    case DISPATCH_TYPES.GET:
      return {
        ...state,
        item: action.payload.data,
      };

    // If no case matches then return same state
    default:
      return state;
  }
};

export default reducer;
