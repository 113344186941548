import { USER_ROLE } from "constants/AppConstant";
import { PiRankingFill } from "react-icons/pi";
import examFormRoutes from "./examFormRoutes";
import examSetupRoutes from "./examSetupRoutes";

const menu = {
  id: "Exam",
  title: "Exam",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: "collapse",
  icon: <PiRankingFill />,
  path: ["/examMenu"],
  children: [examSetupRoutes, examFormRoutes],
};

export default menu;
