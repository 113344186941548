import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const LeaveSetup = React.lazy(() => import("modules/user/leaveSetup"));

const routes = {
  element: <LeaveSetup />,
  title: "Leave Setup",
  type: "item",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: [
    "/leave/types",
    "/leave/reasons",
    "/leave/userallocations",
    "/leave/userdetails",
    "/leave/userleaverelievers",
  ],
};

export default routes;
