export const FONTS = Object.freeze({
  LIGHT: "300",
  REGULAR: "400",
  MEDIUM: "500",
  SEMI_BOLD: "600",
  BOLD: "700",
});

const cardRadius = 16;
const theme = {
  spacing: 4,
  cardRadius: cardRadius,
  direction: "ltr",
  palette: {
    mode: "light",
    primary: {
      main: "#2997ff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fb4f67",
    },
    background: {
      paper: "#FFFFFF",
      default: "#F4F7FE",
    },
    text: {
      primary: "rgb(17, 24, 39)",
      secondary: "rgb(107, 114, 128)",
      disabled: "rgb(149, 156, 169)",
    },
    warning: {
      main: "#FF5252",
      light: "#FFECDC",
    },
    gray: {
      50: "#fafafa",
      100: "#F5F6FA",
      200: "#edf2f7",
      300: "#E0E0E0",
      400: "#c5c6cb",
      500: "#A8A8A8",
      600: "#666666",
      700: "#4a5568",
      800: "#201e21",
      900: "#1a202c",
      A100: "#d5d5d5",
      A200: "#aaaaaa",
      A400: "#303030",
      A700: "#616161",
    },
  },
  status: {
    danger: "orange",
  },
  divider: "rgba(224, 224, 224, 1)",
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontOpticalSizing: "auto",
    fontSize: 14,
    fontWeight: 400,

    h1: {
      fontSize: 22,
      fontWeight: 600,
    },
    h2: {
      fontSize: 20,
      fontWeight: 500,
    },
    h3: {
      fontSize: 18,
      fontWeight: 500,
    },
    h4: {
      fontSize: 16,
      fontWeight: 500,
    },
    h5: {
      fontSize: 14,
      fontWeight: 500,
    },
    h6: {
      fontSize: 12,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 14,
    },

    subtitle2: {
      fontSize: 16,
    },
    body1: {
      fontSize: 15,
    },
    body2: {
      fontSize: 12,
    },
  },
  components: {
    Typography: {
      defaultProps: {
        component: "span",
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: cardRadius,
        },
      },
    },
    MuiCardLg: {
      styleOverrides: {
        root: {
          // apply theme's border-radius instead of component's default
          borderRadius: cardRadius,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: cardRadius,
          boxShadow: "0px 10px 10px 4px rgba(0, 0, 0, 0.04)",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          borderRadius: cardRadius / 2,
          textTransform: "capitalize",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          borderRadius: cardRadius / 2,
          textTransform: "capitalize",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: cardRadius / 2,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: cardRadius / 2,
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiIconButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiLink: {
      styleOverrides: {
        defaultProps: {
          color: "primary.main",
        },
        root: {
          fontWeight: FONTS.BOLD,
          textDecoration: "none",
        },
      },
    },
  },
};

const APP_CURRENCY = "INR";

export const AppSettings = Object.freeze({
  ADMIN: {
    currency: APP_CURRENCY,
    menuStyle: "rounded-reverse",
    navStyle: "ADMIN",
    headerType: "FIXED",
    loginURL: "/login",
    initialURL: "/admin/dashboard",
    footer: false,
    sidebar: {
      borderColor: "#757575",
      menuStyle: "default",
      allowSidebarBgImage: false,
      sidebarBgImageId: 1,
      sidebarBgColor: "#0e0e0ede",
      sidebarTextColor: "#fff",
      sidebarHeaderColor: "#fff",
      sidebarMenuSelectedBgColor: "#079CE9",
      sidebarMenuSelectedTextColor: "#fff",
    },
    theme: {
      ...theme,
      palette: {
        ...theme.palette,
        mode: "light",
        primary: {
          main: "#0A8FDC",
          contrastText: "#fff",
        },
        secondary: {
          main: "#F04F47",
        },
        background: {
          paper: "#FFFFFF",
          default: "#F4F7FE",
        },
        text: {
          primary: "rgb(17, 24, 39)",
          secondary: "rgb(107, 114, 128)",
          disabled: "rgb(149, 156, 169)",
        },
      },
    },
    themeStyle: "standard",
    themeMode: "light",
  },
  PUBLIC: {
    currency: APP_CURRENCY,
    menuStyle: "default",
    navStyle: "PUBLIC",
    loginURL: "/home",
    initialURL: "/home",
    headerType: "FIXED",
    permissions: {},
    footer: true,
    sidebar: {
      borderColor: "#757575",
      menuStyle: "default",
      allowSidebarBgImage: false,
      sidebarBgColor: "#fff",
      sidebarTextColor: "rgba(0, 0, 0, 0.87)",
      sidebarHeaderColor: "#fff",
      sidebarMenuSelectedBgColor: "#313541",
      sidebarMenuSelectedTextColor: "#fff",
    },
    theme: {
      ...theme,
      palette: {
        ...theme.palette,
        mode: "light",
        primary: {
          main: "#6E3A4B",
          contrastText: "#fff",
        },
        secondary: {
          main: "#D38C48",
        },
        background: {
          paper: "#FFFFFF",
          default: "#FCF8F5",
        },
        text: {
          primary: "rgb(17, 24, 39)",
          secondary: "rgb(107, 114, 128)",
          disabled: "rgb(149, 156, 169)",
        },
      },
    },
    themeStyle: "standard",
    themeMode: "light",
  },
  STUDENT: {
    currency: APP_CURRENCY,
    menuStyle: "default",
    navStyle: "STUDENT",
    loginURL: "/studentlogin",
    initialURL: "/dashboard",
    headerType: "FIXED",
    footer: true,
    sidebar: {
      borderColor: "#757575",
      menuStyle: "default",
      allowSidebarBgImage: false,
      sidebarBgColor: "#fff",
      sidebarTextColor: "rgba(0, 0, 0, 0.87)",
      sidebarHeaderColor: "#fff",
      sidebarMenuSelectedBgColor: "#313541",
      sidebarMenuSelectedTextColor: "#fff",
    },
    theme: {
      ...theme,
      palette: {
        ...theme.palette,
        mode: "light",
        primary: {
          main: "#6E3A4B",
          contrastText: "#fff",
        },
        secondary: {
          main: "#D38C48",
        },
        background: {
          paper: "#FFFFFF",
          default: "#FCF8F5",
        },
        text: {
          primary: "rgb(17, 24, 39)",
          secondary: "rgb(107, 114, 128)",
          disabled: "rgb(149, 156, 169)",
        },
      },
    },
    themeStyle: "standard",
    themeMode: "light",
  },
});

export default AppSettings;
