//API

export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";
export const TOGGLE_APP_DRAWER = "toggle_app_drawer";
export const UPDATING_CONTENT = "updating_content";

export const DISPATCH_TYPES = Object.freeze({
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  GET: "GET",
  GET_ALL: "GET_ALL",
  DELETE: "DELETE",
  SET_ITEM: "SET_ITEM",
  EXPORT: "EXPORT",
  PRINT: "PRINT",
});

export const PERMISSION = Object.freeze({
  SET_PERMISSIONS: "set_permissions",
  REMOVE_PERMISSIONS: "remove_permissions",
});

export const NOTIFICATION_MESSAGES = Object.freeze({
  FETCH_START: "fetch_start",
  FETCH_SUCCESS: "fetch_success",
  FETCH_ERROR: "fetch_error",
  SHOW_MESSAGE: "show_message",
  HIDE_MESSAGE: "hide_message",
  TOGGLE_APP_DRAWER: "toggle_app_drawer",
  UPDATING_CONTENT: "updating_content",
});

export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const GET = "GET";
export const GET_ALL = "GET_ALL";
export const DELETE = "DELETE";
