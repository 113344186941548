import PropTypes from "prop-types";
import BloodGroupProvider from "./bloodGroupContext";
import CasteCategoryProvider from "./casteCategoryContext";
import CountryProvider from "./countryContext";
import DistrictProvider from "./districtContext";
import GenderProvider from "./genderContext";
import HolidayProvider from "./holidayContext";
import LayoutTypeProvider from "./layoutTypeContext";
import MasHolidayProvider from "./masHolidayContext";
import ModuleProvider from "./moduleContext";
import MongodbBackupProvider from "./mongodbBackupContext";
import MotherTongueProvider from "./motherTongueContext";
import OccupationProvider from "./occupationContext";
import PermissionProvider from "./permissionContext";
import ReligionProvider from "./religionContext";
import RouteProvider from "./routeContext";
import StateProvider from "./stateContext";
import UserRoleProvider from "./userRoleContext";

const SystemMasterProvider = ({ children }) => {
  return (
    <MasHolidayProvider>
      <HolidayProvider>
        <LayoutTypeProvider>
          <CountryProvider>
            <GenderProvider>
              <RouteProvider>
                <ModuleProvider>
                  <PermissionProvider>
                    <OccupationProvider>
                      <BloodGroupProvider>
                        <CasteCategoryProvider>
                          <ReligionProvider>
                            <MotherTongueProvider>
                              <StateProvider>
                                <DistrictProvider>
                                  <MongodbBackupProvider>
                                    <UserRoleProvider>
                                      {children}
                                    </UserRoleProvider>
                                  </MongodbBackupProvider>
                                </DistrictProvider>
                              </StateProvider>
                            </MotherTongueProvider>
                          </ReligionProvider>
                        </CasteCategoryProvider>
                      </BloodGroupProvider>
                    </OccupationProvider>
                  </PermissionProvider>
                </ModuleProvider>
              </RouteProvider>
            </GenderProvider>
          </CountryProvider>
        </LayoutTypeProvider>
      </HolidayProvider>
    </MasHolidayProvider>
  );
};

export default SystemMasterProvider;

SystemMasterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
