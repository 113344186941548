import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const VerifyQuestion = React.lazy(() =>
  import("modules/master/questionBank/verifyQuestion")
);

const routes = {
  element: <VerifyQuestion />,
  title: "Verify Question",
  type: "item",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ["/question/verify"],
};

export default routes;
