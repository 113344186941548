import PropTypes from "prop-types";
import CompetitiveTagProvider from "./competitiveTagContext";
import DifficultyLevelProvider from "./difficultyLevelContext";
import DivisionGroupWiseTopicAllocationProvider from "./divisionGroupWiseTopicAllocationContext";
import DivisionSubjectTopicProvider from "./divisionSubjectTopicContext";
import QuestionProvider from "./questionContext";
import QuestionGroupProvider from "./questionGroupContext";
import QuestionTypeProvider from "./questionTypeContext";
import SourceTagProvider from "./sourceTagContext";
import SubjectProvider from "./subjectContext";
import SubjectTopicProvider from "./subjectTopicContext";

const QuestionBankProvider = ({ children }) => {
  return (
    <QuestionGroupProvider>
      <QuestionProvider>
        <DivisionGroupWiseTopicAllocationProvider>
          <DivisionSubjectTopicProvider>
            <CompetitiveTagProvider>
              <DifficultyLevelProvider>
                <QuestionTypeProvider>
                  <SourceTagProvider>
                    <SubjectProvider>
                      <SubjectTopicProvider>{children}</SubjectTopicProvider>
                    </SubjectProvider>
                  </SourceTagProvider>
                </QuestionTypeProvider>
              </DifficultyLevelProvider>
            </CompetitiveTagProvider>
          </DivisionSubjectTopicProvider>
        </DivisionGroupWiseTopicAllocationProvider>
      </QuestionProvider>
    </QuestionGroupProvider>
  );
};

export default QuestionBankProvider;

QuestionBankProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
