import Zoom from "@mui/material/Zoom";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const AppTooltip = ({ title, children = <></>, placement = "top", sx }) => {
  return (
    <Tooltip
      title={title}
      TransitionComponent={Zoom}
      placement={placement}
      arrow
      sx={{
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: sx?.maxWidth ? sx?.maxWidth : undefined,
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
export default AppTooltip;
