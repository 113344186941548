import PropTypes from "prop-types";
import ExamGroupProvider from "./examGroupContext";
import ExamPatternTemplateProvider from "./examPatternTemplateContext";
import ExamQuestionProvider from "./examQuestionContext";
import ExamScheduleProvider from "./examScheduleContext";
import ExamTypeProvider from "./examTypeContext";

const ExamProvider = ({ children }) => {
  return (
    <ExamQuestionProvider>
      <ExamPatternTemplateProvider>
        <ExamGroupProvider>
          <ExamScheduleProvider>
            <ExamTypeProvider>{children} </ExamTypeProvider>
          </ExamScheduleProvider>
        </ExamGroupProvider>
      </ExamPatternTemplateProvider>
    </ExamQuestionProvider>
  );
};

export default ExamProvider;

ExamProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
